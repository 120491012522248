import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Spinner } from 'wix-ui-tpa/cssVars';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import styles from './Widget.scss';

export const CheckoutLoader: React.FC<{ loading: boolean }> = ({ loading, children }) => {
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (loading && ref.current) {
      smoothScrollIntoView(ref.current, { block: 'center' });
    }
  }, [loading]);

  return (
    <div ref={ref} className={cn(styles.loaderRoot, { [styles.loading]: loading })}>
      <div className={styles.content}>{children}</div>
      {loading && (
        <div data-hook={VIEWER_HOOKS.CHECKOUT_LOADER} className={styles.loaderContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
